// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-get-involved-js": () => import("./../../../src/pages/get-involved.js" /* webpackChunkName: "component---src-pages-get-involved-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-topic-assets-js": () => import("./../../../src/pages/topic/assets.js" /* webpackChunkName: "component---src-pages-topic-assets-js" */),
  "component---src-pages-topic-customer-js": () => import("./../../../src/pages/topic/customer.js" /* webpackChunkName: "component---src-pages-topic-customer-js" */),
  "component---src-pages-topic-innovation-js": () => import("./../../../src/pages/topic/innovation.js" /* webpackChunkName: "component---src-pages-topic-innovation-js" */),
  "component---src-pages-topic-workforce-js": () => import("./../../../src/pages/topic/workforce.js" /* webpackChunkName: "component---src-pages-topic-workforce-js" */)
}

